@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: MulishNormal;
    font-weight: 400;
    src: url("./fonts/Mulish-Black.ttf") format("ttf");
  }
  @font-face {
    font-family: PtSans;
    font-weight: 400;
    src: url("./fonts/PTSans-Regular.ttf") format("ttf");
  }
}

input:disabled {
  background: #f7f0f0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.warning {
  width: 430px;
  margin-left: 70px;
}
/* .tooltip-background{
  background-color: #af4860fd !important;
}  */
