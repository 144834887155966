.openSidebar {
  width: 50%;
  visibility: visible;
  animation: open 0.1s ease-in;
}
.closeSidebar {
  animation: close 0.1s ease-out;
  width: 0%;
  visibility: hidden;
}
@keyframes open {
  from {
    width: 0px;
    visibility: hidden;
  }
  to {
    width: 50%;
    visibility: visible;
  }
}
@keyframes close {
  from {
    width: 50%;
    visibility: visible;
  }
  to {
    width: 0px;
    visibility: hidden;
  }
}
